import styled from 'styled-components';

const AboutHomeStyle = styled.section`
	--distanceTitleDescription: var(--sp6x);
	--statisticTB: var(--sp5x);
	--statisticLR: var(--sp5x);
	--statisticDistanceNumberTitle: var(--sp6x);
	--radiusBorder: var(--sp5x);
	--sizeStatistics: var(--sp13x);
	--distanceSecond: var(--sp8x);
	--distanceThree: var(--sp11x);

	position: relative;
	z-index: 10;
	margin-top: var(--sectionDistance);
	margin-bottom: calc(var(--sectionDistance) / 2);

	.wrapper-title-description {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: var(--distanceTitleDescription);
	}

	.wrapper-statistics {
		margin-top: var(--distanceTitleDescription);
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: var(--gutter);
		height: fit-content;

		> :nth-child(1) {
			transform: translateY(calc(200% + calc(var(--distanceThree) + var(--distanceSecond))));
		}

		> :nth-child(2) {
			margin-top: var(--distanceSecond);
			transform: translateY(calc(200% - var(--distanceSecond)));
		}

		> :nth-child(3) {
			margin-top: calc(var(--distanceThree) + var(--distanceSecond));
			transform: translateY(calc(200% - calc((var(--distanceThree) + var(--distanceSecond)) * 2)));
		}

		.item-wrapper-statistics {
			background-color: var(--lightBlue);
			padding: var(--statisticTB) var(--statisticLR);
			border-radius: var(--radiusBorder);
			height: fit-content;
		}

		.statistics-text-title,
		.statistics-text-number {
			color: var(--white);
		}

		.statistics-text-number {
			font-size: var(--sizeStatistics);
			line-height: var(--lineHeightM);
		}

		.statistics-text-title {
			margin-top: var(--statisticDistanceNumberTitle);
		}
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) {
		--distanceTitleDescription: var(--sp5x);
		--statisticTB: var(--sp4x);
		--statisticLR: var(--sp4x);
		--statisticDistanceNumberTitle: var(--sp4x);
		--radiusBorder: var(--sp4x);
		--sizeStatistics: var(--sp10x);
		--distanceSecond: var(--sp5x);
		--distanceThree: var(--sp8x);
	}
	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) {
		--distanceTitleDescription: var(--sp4x);
		--statisticTB: var(--sp4x);
		--statisticLR: var(--sp4x);
		--statisticDistanceNumberTitle: var(--sp3x);
		--radiusBorder: var(--sp3x);
		--sizeStatistics: var(--sp9x);
		--distanceSecond: var(--sp4x);
		--distanceThree: var(--sp7x);
	}
	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) {
		--distanceTitleDescription: var(--sp3x);
		--statisticTB: var(--sp4x);
		--statisticLR: var(--sp4x);
		--statisticDistanceNumberTitle: var(--sp3x);
		--radiusBorder: var(--sp2-5x);
		--sizeStatistics: var(--sp8x);
		--distanceSecond: var(--sp5x);
		--distanceThree: var(--sp8x);
	}
	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) {
		--distanceTitleDescription: var(--sp2-5x);
		--statisticTB: var(--sp3x);
		--statisticLR: var(--sp3x);
		--statisticDistanceNumberTitle: var(--sp2x);
		--radiusBorder: var(--sp2-5x);
		--sizeStatistics: var(--sp7x);
		--distanceSecond: var(--sp5x);
		--distanceThree: var(--sp5x);
	}
	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) {
		--distanceTitleDescription: var(--sp2x);
		--statisticTB: var(--sp2x);
		--statisticLR: var(--sp2x);
		--statisticDistanceNumberTitle: var(--sp1x);
		--radiusBorder: calc(var(--sp1x) + var(--sp0-5x) / 2);
		--sizeStatistics: var(--sp5x);
		--distanceSecond: var(--sp4x);
		--distanceThree: var(--sp4x);
	}
	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--statisticTB: var(--sp3x);
		--statisticLR: var(--sp3x);
		--statisticDistanceNumberTitle: var(--sp2x);
		--radiusBorder: var(--sp2x);
		--sizeStatistics: var(--sp6x);
		--distanceSecond: 0px;
		--distanceThree: 0px;

		.wrapper-statistics {
			grid-template-columns: 1fr;
			grid-template-rows: repeat(3, 1fr);
		}

		.empty {
			display: none;
		}
	}
`;
export default AboutHomeStyle;
