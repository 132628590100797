//* Styles
import ItemCardStyle from './style';

//* Component
import Text from '@/components/Text';
import LottieAnimation from '@/components/LottieAnimation';

const ItemCard = ({ description, number, lottie, color }) => {
	return (
		<ItemCardStyle style={{ '--colorBG': `var(--${color ? color : 'blue400'})` }}>
			<div className='wrapper-number-description'>
				<Text className={'h2 number-text font-montserrat-arm-medium'}>{number < 10 ? '0' + number : number}</Text>
				<Text className={'p1'}>{description}</Text>
			</div>
			<div className='wrapper-lottie'>
				<LottieAnimation
					autoplay={true}
					loop={true}
					animData={lottie}
				/>
			</div>
		</ItemCardStyle>
	);
};

export default ItemCard;
