import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useEffect, useRef } from 'react';
import gsap from 'gsap/all';

//* Styles
import WhatWeDoStyle from './style';

//* HOO'C
import { withUIContext } from '@/context';

//* Lottie Files
import lottie1 from '@/lottieFiles/what-we-do/1.json';
import lottie2 from '@/lottieFiles/what-we-do/2.json';
import lottie3 from '@/lottieFiles/what-we-do/3.json';
import lottie4 from '@/lottieFiles/what-we-do/4.json';

//* Component
import Container from '@/components/Container';
import Text from '@/components/Text';
import ItemCard from './ItemCard';

const WhatWeDo = ({ title, description, items, winWidth }) => {
	//! Create data Lottie Animation
	const lottieData = [lottie1, lottie2, lottie3, lottie4];
	const dataColor = ['blue100', 'blue200', 'blue300', 'cartColor'];
	const colorInverse = ['#C9E0F2', '#D1E2EE', '#BED9E9', '#B1CDDE'];

	//! Ref
	const leftBlock = useRef(null);
	const wrapperAll = useRef(null);
	const cardWrapper = useRef(null);
	const pinSpacingRef = useRef(null);
	const leftInnerRef = useRef();

	useEffect(() => {
		if (leftBlock.current && items.length) {
			const everyCard = Array.from(cardWrapper.current.children);

			//! Controller spacing to scroll
			let spacingController;

			if (winWidth <= 768) {
				spacingController = parseInt(window.getComputedStyle(leftBlock.current).marginBlockStart);
			} else {
				spacingController = parseInt(window.getComputedStyle(pinSpacingRef.current).marginBlockStart);
			}

			//! Calculate other distance
			const numberWidth = everyCard[0].querySelector('.number-text').getBoundingClientRect().height;
			const paddingCalculate = parseInt(window.getComputedStyle(everyCard[0]).paddingBlockStart);
			const lastCard = everyCard[everyCard.length - 1];

			//! Calculate title description block width

			const lastCardHeightFromLeft = everyCard[everyCard.length - 1].clientHeight;
			const totalTitleDescriptionWidth = numberWidth * everyCard.length + paddingCalculate + lastCardHeightFromLeft;
			leftInnerRef.current.style.height = ` ${totalTitleDescriptionWidth}px`;

			//! Calculate the height of the last card
			let lastCardHeight = lastCard.clientHeight;

			//! Calculate the total offset based on the "animation-item" attribute of each card
			const totalOffset = (numberWidth + paddingCalculate) * (everyCard.length - 1) + spacingController;

			//! Add the total offset to the height of the last card
			lastCardHeight += totalOffset;

			const timelines = everyCard.map((item, index) => {
				let calculatedSpacing = index * (numberWidth + paddingCalculate);

				return gsap
					.timeline({
						scrollTrigger: {
							trigger: item,
							start: 'top top+=' + (index == 0 ? spacingController + 'px' : calculatedSpacing + spacingController + 'px'),
							endTrigger: '.container-trigger',
							//! Dynamically create height
							end: `bottom top+=${lastCardHeight}px`,
							pin: true,
							pinSpacing: false,
							scrub: true,
							// markers: true,

							// onEnter: () => {
							// 	gsap.set(item, {
							// 		backgroundColor: `${colorInverse[index]}`,
							// 	});
							// },
							// onLeaveBack: () => {
							// 	gsap.set(item, {
							// 		backgroundColor: `var(--${dataColor[index]})`,
							// 	});
							// },
						},
					})
					.to(item, { backgroundColor: colorInverse[index], opacity: `${index == 0 ? 0.5 : 1}` });
			});
			let mainScrollTrigger;
			if (winWidth >= 768) {
				const widthLeft = leftBlock.current.clientHeight;
				mainScrollTrigger = ScrollTrigger.create({
					trigger: wrapperAll.current,
					scrub: 0.5,
					start: 'top top',
					endTrigger: '.container-trigger',
					end: `bottom top+=${widthLeft + spacingController}`,
					pin: leftBlock.current,
					pinSpacing: false,
					// markers: true,
				});
			}

			return () => {
				mainScrollTrigger && mainScrollTrigger.kill();
				timelines.forEach((tl) => tl.scrollTrigger.kill());
			};
		}
	}, [winWidth]);

	return (
		<WhatWeDoStyle ref={wrapperAll}>
			<Container
				isSection
				row
				className={'container-trigger'}>
				<div className='col-5 col-t-6 col-m-12'>
					<div
						ref={leftBlock}
						className='wrapper-title-description-right-block'>
						<div
							ref={leftInnerRef}
							className='inner-wrapper'>
							<Text className={'h2 font-montserrat-arm-medium uppercase'}>{title}</Text>
							<Text className={'p1'}>{description}</Text>
						</div>
					</div>
				</div>
				<div className='empty col-1' />
				<div className='col-6 col-t-6 col-m-12'>
					<div
						className='pin-spacing-wrapper'
						ref={pinSpacingRef}>
						<div
							ref={cardWrapper}
							className='wrapper-card cards'
							animation='list'>
							{items.length &&
								items.map((item, i) => {
									return (
										<ItemCard
											key={i}
											color={dataColor[i]}
											lottie={lottieData[i]}
											number={i + 1}
											{...item}
										/>
									);
								})}
						</div>
					</div>
				</div>
			</Container>
		</WhatWeDoStyle>
	);
};

export default withUIContext(WhatWeDo, ['winWidth']);
