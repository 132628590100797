import { useMemo } from 'react';

//* Styles
import PartnersStyle from './style';

//* HOO'C
import { withDataContext, withUIContext } from '@/context';

//* Component
import Image from '@/components/Image';
import Banner from '@/components/Banner';
import Container from '@/components/Container';
import CustomLink from '@/components/CustomLink';

const Partners = ({ globalData, winWidth }) => {
	const partnersData = globalData.partners;

	const partnerItem = useMemo(() => {
		if (partnersData) {
			const isMobile = winWidth < 768;

			return partnersData.length
				? partnersData.map((item, index) => {
						if (isMobile) {
							if (index % 2 === 0) {
								return (
									<div
										key={`row-${index / 2}`}
										className='item-image'>
										<CustomLink
											external
											url={item.additional_info.link}>
											<Image
												src={item.image.src}
												alt={item.image.alt}
											/>
										</CustomLink>
										{partnersData[index + 1] && (
											<CustomLink
												external
												url={partnersData[index + 1].additional_info.link}>
												<Image
													src={partnersData[index + 1].image.src}
													alt={partnersData[index + 1].image.alt}
												/>
											</CustomLink>
										)}
									</div>
								);
							}
							return null;
						} else {
							return (
								<div
									key={index}
									className='item-image'>
									<CustomLink
										external
										url={item.additional_info.link}>
										<Image
											src={item.image.src}
											alt={item.image.alt}
										/>
									</CustomLink>
								</div>
							);
						}
				  })
				: null;
		}
	}, [partnersData, winWidth]);

	return partnersData ? (
		<PartnersStyle>
			<Container isSection>
				<div className='wrapper-banner-partner'>
					<div className='inner-wrapper'>
						{/* <Banner
							duration={3}
							trigger={true}
							className={'partners-banner'}
							component={partnerItem}
						/> */}
						<div className='partners-banner'>{partnerItem}</div>
					</div>
				</div>
			</Container>
		</PartnersStyle>
	) : null;
};

export default withUIContext(withDataContext(Partners, ['globalData']), ['winWidth']);
