import styled from 'styled-components';

const HeroStyle = styled.section`
	--rightTextMT: var(--sp4x);
	--rightTextMB: var(--sp3x);
	--rightBorderRadius: var(--sp4x);
	--padRightTB: var(--sp6x);
	--padRightLR: var(--sp6x);
	--distanceRightB: var(--sp12x);
	--leftTextMT: var(--sp6x);
	--leftTextMB: var(--sp5x);
	--distanceLeftB: var(--sp14x);
	--sizeArrow: var(--sp4x);
	/* --sizeImage: 51.1%; */
	--sizeImage: 100svh;

	overflow: hidden;
	position: relative;

	.empty-background {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 10;
		background-color: var(--backgroundColor) !important;
	}

	.wrapper-grid {
		padding: 0 var(--contPaddingLR);
		display: grid;
		grid-template-columns: 6fr 1fr 5fr;
	}

	.inner-item-wrapper {
		position: relative;

		/* .empty-background-black {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 3;
			background-color: var(--black);
			opacity: 0.15;
			will-change: position;
		} */

		.info-wrapper {
			position: absolute;
			z-index: 4;
			bottom: 0;
		}

		.text {
			color: var(--white);
		}
	}

	.wrapper-left {
		position: relative;
		z-index: 5;
		margin-bottom: var(--distanceLeftB);

		/* .empty-circle-for-black {
			position: absolute;
			z-index: -1;
			top: 50%;
			left: 50%;
			width: 140%;
			height: 140%;
			transform: translate(-50%, -50%);
			border-radius: 100%;
			overflow: hidden;
			opacity: 0.4;
			background: radial-gradient(78.87% 78.87% at 25.31% 74.15%, #000000 0%, rgba(0, 0, 0, 0) 100%); 
			filter: blur(76px);
			-webkit-filter: blur(76px);
		} */

		.left-middle-text {
			margin-top: var(--leftTextMT);
			margin-bottom: var(--leftTextMB);
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
	}

	.wrapper-right {
		overflow: hidden;
		position: relative;
		z-index: 5;
		height: 100%;
		width: 100%;
		display: flex;
		align-items: flex-end;
		flex-wrap: nowrap;

		.inner-wrapper-right {
			cursor: pointer;
			width: 100%;
			margin-bottom: var(--distanceRightB);
			background-color: var(--blue1000);
			border-radius: var(--rightBorderRadius);
			padding: var(--padRightTB) var(--padRightLR);
			will-change: transform;
		}

		.right-middle-text {
			margin-top: var(--rightTextMT);
			margin-bottom: var(--rightTextMB);
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			will-change: transform;
		}
	}

	.wrapper-next {
		display: flex;
		align-items: center;
		gap: var(--sp1x);
		width: fit-content;
		cursor: pointer;
		will-change: transform;

		.arrow-icon {
			font-size: var(--sizeArrow);
			transition: var(--trTime) ease-out;
			transform: translateX(0px) rotate(-90deg);
			animation: arrowAnim 0.6s infinite ease-out;
			animation-play-state: paused;
		}
	}

	.wrapper-button {
		opacity: 0;
	}

	.wrapper-image {
		position: relative;

		.image-cont {
			--proportion: var(--sizeImage);

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
				background-color: var(--black);
				opacity: 0.15;
			}

			opacity: 0;
		}

		.wrapper-image-2 {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		.image-1 {
			position: relative;
			z-index: 1;
			transform: scale(0);
			opacity: 0;
		}
	}

	.content-right-wrapper {
		opacity: 0;
		height: 100%;
		overflow: hidden;
		display: flex;
		align-items: flex-end;
	}

	@keyframes arrowAnim {
		50% {
			transform: translateX(20%) rotate(-90deg);
		}
		100% {
			transform: translateX(0%) rotate(-90deg);
		}
	}

	@media (hover: hover) {
		.inner-wrapper-right {
			&:hover {
				.arrow-icon {
					animation: arrowAnim 0.5s infinite ease-out;
				}
			}
		}
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) {
		--rightTextMT: var(--sp2x);
		--rightTextMB: var(--sp4x);
		--rightBorderRadius: var(--sp3x);
		--padRightTB: var(--sp4x);
		--padRightLR: var(--sp4x);
		--distanceRightB: var(--sp7x);
		--leftTextMT: var(--sp4x);
		--leftTextMB: var(--sp6x);
		--distanceLeftB: var(--sp9x);
		/* --sizeImage: 50.95%; */
		--sizeArrow: var(--sp3x);
	}
	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) {
		--rightTextMT: var(--sp2-5x);
		--rightTextMB: var(--sp3x);
		--rightBorderRadius: var(--sp3x);
		--padRightTB: var(--sp3x);
		--padRightLR: var(--sp3x);
		--distanceRightB: var(--sp6x);
		--leftTextMT: var(--sp3x);
		--leftTextMB: var(--sp2x);
		--distanceLeftB: var(--sp7x);
		/* --sizeImage: 56.81%; */
	}
	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) {
		--rightTextMT: var(--sp2x);
		--rightTextMB: var(--sp2-5x);
		--rightBorderRadius: var(--sp2-5x);
		--padRightTB: var(--sp2-5x);
		--padRightLR: var(--sp2-5x);
		--distanceRightB: var(--sp5x);
		--leftTextMT: var(--sp2-5x);
		--leftTextMB: var(--sp4x);
		--distanceLeftB: var(--sp5x);
		/* --sizeImage: 56.847%; */
		--sizeArrow: var(--sp2-5x);
	}
	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) {
		--rightTextMT: var(--sp2x);
		--rightTextMB: var(--sp2x);
		--rightBorderRadius: var(--sp2x);
		--padRightTB: var(--sp2x);
		--padRightLR: var(--sp2x);
		--distanceRightB: var(--sp6x);
		--leftTextMT: var(--sp2x);
		--leftTextMB: var(--sp3x);
		--distanceLeftB: var(--sp7x);
		/* --sizeImage: 56.847%; */
	}
	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) {
		--rightTextMT: var(--sp2x);
		--rightTextMB: var(--sp2x);
		--rightBorderRadius: var(--sp2x);
		--padRightTB: var(--sp2x);
		--padRightLR: var(--sp2x);
		--distanceRightB: var(--sp6x);
		--leftTextMT: var(--sp2x);
		--leftTextMB: var(--sp4x);
		--distanceLeftB: var(--sp6x);
		/* --sizeImage: 126.8%; */

		.wrapper-grid {
			display: grid;
			grid-template-columns: 1fr;
			grid-template-rows: auto 0fr auto;

			.wrapper-left {
				max-width: calc(100% / 12 * 9);
			}
		}
	}
	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--rightTextMT: var(--sp1-5x);
		--rightTextMB: var(--sp1-5x);
		--rightBorderRadius: var(--sp2x);
		--padRightTB: var(--sp2x);
		--padRightLR: var(--sp2x);
		--distanceRightB: var(--sp4x);
		--leftTextMT: var(--sp2x);
		--leftTextMB: var(--sp4x);
		--distanceLeftB: var(--sp5x);
		/* --sizeImage: 216.5%; */

		.empty {
			display: none;
		}

		.wrapper-grid {
			padding: 0 var(--contPaddingLR);
			display: grid;
			grid-template-rows: auto 0fr auto;

			.wrapper-left {
				max-width: 100%;
			}
		}
	}
`;
export default HeroStyle;
