import { useEffect, useRef, useState } from 'react';
import { SplitText } from 'gsap/SplitText';
import gsap from 'gsap/all';

//* Styles
import HeroStyle from './style';

//* HOO'C
import { withLanguageContext } from '@/context';

//* Component
import Text from '@/components/Text';
import Icon from '@/components/Icon';
import Image from '@/components/Image';
import Button from '@/components/Button';
import ScrollActive from '@/components/ScrollActive';

const Hero = ({ heroData, translate }) => {
	//! State
	const [data, setData] = useState(heroData);
	const [load, onLoad] = useState(false);

	//! Refs
	const reverse = useRef(false);
	const firstLoad = useRef(true);
	const timLine = useRef();
	const timLine2 = useRef();
	const timLine3 = useRef();
	const timLine4 = useRef();

	const titleRightRef = useRef(null);
	const image1 = useRef();
	const image2 = useRef();
	const innerContentRefWidth = useRef();
	const leftDescriptionRef = useRef();
	const contentRightWrapper = useRef();
	const buttonRef = useRef();
	// const emptyBackground = useRef();

	//! Reverse data
	function clickItemNext() {
		reverse.current = !reverse.current;
		setData([...data].reverse());
	}

	//! first load and nexting animation
	useEffect(() => {
		if (data && load) {
			if (firstLoad.current) {
				firstLoad.current = false;
				// emptyBackground.current.remove();

				gsap.fromTo(
					image1.current,
					{ opacity: 0, scale: 0 },
					{
						opacity: 1,
						scale: 1,
						duration: 0.6,
						ease: 'power2.out',
						delay: 0.05,
					}
				);
				const widthInnerContent = innerContentRefWidth.current.clientWidth;

				innerContentRefWidth.current.style.width = widthInnerContent + 'px';

				gsap.fromTo(
					contentRightWrapper.current,
					{
						opacity: 1,
						width: '0',
					},
					{
						opacity: 1,
						width: '100%',
						duration: 0.6,
						ease: 'none',
						delay: 0.4,
					}
				);

				gsap.fromTo(
					leftDescriptionRef.current,
					{ opacity: 0 },
					{
						delay: 0.6,
						ease: 'power2.out',
						duration: 0.6,
						opacity: 1,
					}
				);

				gsap.fromTo(
					buttonRef.current,
					{
						opacity: 0,
					},
					{ opacity: 1, duration: 0.6, ease: 'power2.out', delay: 0.6 }
				);
			} else {
				const mySplitText = new SplitText(titleRightRef.current, { type: `lines lines` });
				if (timLine.current) {
					timLine.current.kill();
					timLine2.current.kill();
					timLine3.current.kill();
					timLine4.current.kill();
				}
				timLine.current = gsap.timeline({ delay: 0, opacity: 0 });
				timLine2.current = gsap.timeline({ delay: 0, opacity: 0 });
				timLine3.current = gsap.timeline({ delay: 0.2 });
				timLine4.current = gsap.timeline({ delay: 0.3 });

				gsap.to(mySplitText.lines, {
					opacity: 0,
					duration: 0,
				});

				timLine.current.fromTo(
					mySplitText.lines,
					{
						y: '100%',
						opacity: 1,
					},
					{
						opacity: 1,
						y: 0,
						ease: 'power2.out',
						duration: 0.5,
						stagger: 0.1,
						delay: 0.1,
					}
				);

				timLine2.current.fromTo(
					leftDescriptionRef.current,
					{ y: '100%', opacity: 0 },
					{
						ease: 'power2.out',
						opacity: 1,
						delay: 0.2,
						duration: 0.5,
						y: 0,
					}
				);

				timLine3.current.fromTo(
					contentRightWrapper.current,
					{
						width: '0',
					},
					{
						width: '100%',
						duration: 0.6,
						ease: 'none',
					}
				);

				timLine4.current.fromTo(
					[...innerContentRefWidth.current.children],
					{
						y: '1vw',
					},
					{
						y: 0,
						duration: 0.6,
						ease: 'power2.out',
						stagger: 0.05,
						delay: 0.2,
					}
				);

				gsap.fromTo(
					buttonRef.current,
					{
						opacity: 0,
						y: '100%',
					},
					{ opacity: 1, duration: 0.6, ease: 'power2.out', delay: 0.2, y: 0 }
				);

				if (reverse.current) {
					gsap.fromTo(image1.current, { opacity: 1, zIndex: 1 }, { zIndex: 1, opacity: 0 });
					gsap.fromTo(
						image2.current,
						{ opacity: 0 },
						{
							zIndex: 2,
							opacity: 1,
							scale: 1,
							duration: 0.6,
							ease: 'power2.out',
						}
					);
				} else {
					gsap.fromTo(image2.current, { opacity: 1, zIndex: 1 }, { zIndex: 1, opacity: 0 });
					gsap.fromTo(
						image1.current,
						{ opacity: 0 },
						{
							zIndex: 2,
							opacity: 1,
							scale: 1,
							duration: 0.6,
							ease: 'power2.out',
						}
					);
				}
			}
		}
	}, [data, load]);

	return (
		<HeroStyle>
			{/* <div
				ref={emptyBackground}
				className='empty-background'
			/> */}
			{data ? (
				<div className='inner-item-wrapper'>
					<div className='wrapper-image'>
						<Image
							onLoad={onLoad}
							src={heroData[0].image.src}
							alt={heroData[0].image.alt}
							ref={image1}
							className={'image-1'}
						/>
						<div className='wrapper-image-2'>
							<Image
								src={heroData[1].image.src}
								alt={heroData[1].image.alt}
								ref={image2}
								className={'image-2'}
							/>
						</div>
					</div>
					<div className='info-wrapper'>
						<div className='wrapper-grid'>
							<div className='col-gap '>
								<div className='wrapper-left'>
									<div className='inner-wrapper-left'>
										<div className='empty-circle-for-black' />
										{load ? (
											<ScrollActive
												animTitle
												// dependencies={data}
												overflow={firstLoad.current}
												allRefs={titleRightRef}
												stagger={0.05}
												delay={firstLoad.current ? 0.3 : 0}>
												<Text
													ref={titleRightRef}
													tag={'h1'}
													className={'first-info-text h1 uppercase  text font-montserrat-arm-medium opacity-0'}>
													{data[0].title}
												</Text>
											</ScrollActive>
										) : null}

										<Text
											ref={leftDescriptionRef}
											className={'first-info-text p1 text left-middle-text opacity-0'}>
											{data[0].description}
										</Text>
										<div
											ref={buttonRef}
											className='wrapper-button'>
											<Button
												className={'secondary reverse'}
												text={'seeMore'}
												external
												url={data[0].additional_info.link}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className='col-gap empty ' />
							<div className='col-gap  '>
								<div className='wrapper-right'>
									<div
										ref={contentRightWrapper}
										className='content-right-wrapper'>
										<div
											onClick={clickItemNext}
											className='inner-wrapper-right'>
											<div ref={innerContentRefWidth}>
												<Text className={'second-info-text h5 font-montserrat-arm-medium text'}>{data[1].title}</Text>
												<Text className={'second-info-text p1 text right-middle-text'}>{data[1].description}</Text>
												<div className='wrapper-next'>
													<Text className={'text'}>{translate('next')}</Text>
													<Icon
														className={'text arrow-icon'}
														name={'arrow'}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : null}
		</HeroStyle>
	);
};

export default withLanguageContext(Hero, ['translate']);
