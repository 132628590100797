import styled from 'styled-components';

const ItemCardStyle = styled.div`
	--sizeLottie: var(--sp35x);
	--itemPadTB: var(--sp6x);
	--itemPadLR: var(--sp6x);
	--radiusBorder: var(--sp5x);
	--distanceTitleDescription: var(--sp4x);
	--cardItemHeight: calc(var(--sp107x) + var(--sp0-5x));

	padding: var(--itemPadTB) var(--itemPadLR);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: var(--colorBG);
	border-radius: var(--radiusBorder);
	transition: background-color var(--trTime);
	min-height: var(--cardItemHeight);

	.wrapper-lottie {
		width: var(--sizeLottie);
	}

	.number-text {
		color: var(--white);
	}

	.wrapper-number-description {
		display: flex;
		flex-direction: column;
		gap: var(--distanceTitleDescription);
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) {
		--sizeLottie: var(--sp27x);
		--itemPadTB: var(--sp5x);
		--itemPadLR: var(--sp5x);
		--radiusBorder: var(--sp4x);
		--distanceTitleDescription: var(--sp3x);
		--cardItemHeight: calc(var(--sp82x) + var(--sp0-5x));
	}
	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) {
		--sizeLottie: var(--sp27x);
		--itemPadTB: var(--sp4x);
		--itemPadLR: var(--sp4x);
		--radiusBorder: var(--sp3x);
		--distanceTitleDescription: var(--sp2-5x);
	}
	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) {
		--sizeLottie: var(--sp15x);
		--itemPadTB: var(--sp2-5x);
		--itemPadLR: var(--sp2-5x);
		--radiusBorder: var(--sp2-5x);
		--distanceTitleDescription: var(--sp2-5x);
		--cardItemHeight: var(--sp51x);
	}
	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) {
		--sizeLottie: var(--sp12x);
		--itemPadTB: var(--sp2-5x);
		--itemPadLR: var(--sp2-5x);
		--radiusBorder: var(--sp2-5x);
		--distanceTitleDescription: var(--sp2-5x);
		--cardItemHeight: var(--sp47x);
	}
	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) {
		--sizeLottie: var(--sp8x);
		--itemPadTB: var(--sp2x);
		--itemPadLR: var(--sp2x);
		--radiusBorder: var(--sp2x);
		--distanceTitleDescription: var(--sp2x);
		--cardItemHeight: calc(var(--sp41x) + var(--sp0-5x));
	}
	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--sizeLottie: var(--sp8x);
		--itemPadTB: var(--sp2x);
		--itemPadLR: var(--sp2x);
		--radiusBorder: var(--sp2x);
		--distanceTitleDescription: var(--sp2x);
		--cardItemHeight: var(--sp39x);
	}
`;
export default ItemCardStyle;
