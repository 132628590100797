import { useRef, useEffect, useState } from 'react';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { gsap } from 'gsap';

//* Style
import BannerStyle from './style';

//* Components
import Resize from '../Resize';

const Banner = ({ className, component, duration, trigger }) => {
	//! Refs
	const imageRefs = useRef();
	const scrollTrigger = useRef();
	const bannerRef = useRef();
	const bannerRefTrigger = useRef();
	const animationStarted = useRef(true);

	//! State
	const [resize, setResize] = useState(false);

	useEffect(() => {
		if (component && imageRefs.current && animationStarted.current) {
			gsap.registerPlugin(ScrollTrigger);

			if (!bannerRef.current) {
				bannerRef.current = gsap.timeline();
			}
			if (!bannerRefTrigger.current) {
				bannerRefTrigger.current = gsap.timeline();
			}

			const time = duration ? duration : 2;

			const childrenWidth = imageRefs.current.children[0].clientWidth;
			const gap = parseInt(window.getComputedStyle(imageRefs.current).gap);
			const itemsLength = imageRefs.current.children.length;
			const totalWidth = (childrenWidth * itemsLength + (gap * itemsLength - 1)) / 2;
			const totalTime = (itemsLength / 2) * time;

			if (trigger) {
				scrollTrigger.current = ScrollTrigger.create({
					trigger: imageRefs.current,
					start: 'center 80%',
					end: 'center top',
					// markers: true,

					once: true,

					onEnter: () => {
						if (animationStarted.current) {
							animationStarted.current = false;
							bannerRefTrigger.current.fromTo(
								imageRefs.current,
								{ x: 0 },
								{
									x: `-${totalWidth}px`,
									duration: totalTime,
									repeat: -1,
									ease: 'none',
								}
							);
						}
					},
				});
			} else {
				bannerRef.current.fromTo(
					imageRefs.current,
					{ x: 0 },
					{
						x: `-${totalWidth}px`,
						duration: totalTime,
						repeat: -1,
						ease: 'none',
					}
				);
			}
		}
		return () => {
			if (bannerRef.current) {
				bannerRef.current.kill();
				bannerRef.current = null;
			}
			if (bannerRefTrigger.current) {
				bannerRefTrigger.current.kill();
				bannerRefTrigger.current = null;
				animationStarted.current = true;
			}
			if (scrollTrigger.current) {
				scrollTrigger.current.kill();
				scrollTrigger.current = null;
			}
		};
	}, [resize]);

	return (
		<BannerStyle
			className={`${className || ''}`}
			ref={imageRefs}>
			<Resize
				main
				setResize={setResize}>
				{component}
				{component}
			</Resize>
		</BannerStyle>
	);
};

export default Banner;
