import styled from 'styled-components';

const IssueSectionStyle = styled.section`
	--staticSectionPadTB: var(--sp7x);
	--staticSectionPadLR: var(--sp7x);
	--distanceTitleMargin: var(--sp5x);
	--distanceDescriptionMargin: var(--sp7x);
	--borderRadiusStaticSection: var(--sp5x);
	--distanceImageContent: var(--sp14x);

	--distanceIssueItem: var(--sp2-5x);
	--radiusImageIssue: var(--sp3x);
	--sizeIssueImage: 136%;

	overflow: hidden;

	.title-issue,
	.description-issue {
		color: var(--white);
	}

	.anim-background {
		position: absolute;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100%;
		background-color: var(--blue1000);
		border-radius: var(--borderRadiusStaticSection);
		transform: scaleX(1.3);
		transition: transform 0.5s ease-out;
		z-index: -1;
	}

	.wrapper-issue {
		position: relative;
		width: 100%;
		padding: var(--staticSectionPadTB) var(--staticSectionPadLR);
		display: grid;
		grid-template-columns: 5fr 7fr;
		gap: var(--distanceImageContent);
	}

	.section-distance {
		margin-bottom: var(--sectionDistance);
	}

	.left-section {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.title-issue {
		margin-bottom: var(--distanceTitleMargin);
	}

	.description-issue {
		margin-bottom: var(--distanceDescriptionMargin);
	}

	.right-section {
		position: relative;
		overflow: hidden;

		.image-banner {
			display: flex;
			width: calc(100%);
			gap: var(--distanceIssueItem);
		}

		.item-image {
			min-width: calc((100% - (var(--distanceIssueItem) * 2)) / 3);
			max-width: calc(100% / 3);
			will-change: transform;
		}

		.image-cont {
			--proportion: var(--sizeIssueImage);
			border-radius: var(--radiusImageIssue);
			overflow: hidden;
		}
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) {
		--staticSectionPadTB: var(--sp5x);
		--staticSectionPadLR: var(--sp5x);
		--distanceTitleMargin: var(--sp4x);
		--distanceDescriptionMargin: var(--sp5x);
		--borderRadiusStaticSection: var(--sp4x);
		--distanceImageContent: var(--sp14x);

		--distanceIssueItem: var(--sp2x);
		--radiusImageIssue: var(--sp3x);
		--sizeIssueImage: 129%;
	}
	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) {
		--staticSectionPadTB: var(--sp4x);
		--staticSectionPadLR: var(--sp4x);
		--distanceTitleMargin: var(--sp3x);
		--distanceDescriptionMargin: var(--sp3x);
		--borderRadiusStaticSection: var(--sp4x);
		--distanceImageContent: var(--sp8x);

		--distanceIssueItem: var(--sp1-5x);
		--radiusImageIssue: var(--sp2-5x);
		--sizeIssueImage: 146.5%;
	}
	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) {
		--staticSectionPadTB: var(--sp3x);
		--staticSectionPadLR: var(--sp3x);
		--distanceTitleMargin: var(--sp3x);
		--distanceDescriptionMargin: var(--sp4x);
		--borderRadiusStaticSection: var(--sp4x);
		--distanceImageContent: var(--sp13x);

		--distanceIssueItem: var(--sp2x);
		--radiusImageIssue: var(--sp2x);
		--sizeIssueImage: 139%;
	}
	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) {
		--borderRadiusStaticSection: var(--sp3x);
		--distanceImageContent: var(--sp7x);

		--distanceIssueItem: var(--sp2x);
		--radiusImageIssue: var(--sp2x);
		--sizeIssueImage: 142%;
	}
	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) {
		--staticSectionPadTB: var(--sp4x);
		--staticSectionPadLR: var(--sp3x);
		--distanceTitleMargin: var(--sp3x);
		--distanceDescriptionMargin: var(--sp3x);
		--borderRadiusStaticSection: var(--sp2x);
		--distanceImageContent: var(--sp4x);

		--distanceIssueItem: var(--sp2x);
		--radiusImageIssue: var(--sp2x);
		--sizeIssueImage: 145.6%;

		.wrapper-issue {
			grid-template-columns: auto;
			grid-template-rows: auto auto;
		}
		.left-section {
			max-width: 60%;
		}
	}
	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--staticSectionPadTB: var(--sp3x);
		--staticSectionPadLR: var(--sp2x);
		--distanceTitleMargin: var(--sp2x);
		--distanceDescriptionMargin: var(--sp2x);
		--borderRadiusStaticSection: var(--sp2-5x);
		--distanceImageContent: var(--sp3x);

		--distanceIssueItem: var(--sp2x);
		--radiusImageIssue: var(--sp2x);
		--sizeIssueImage: 138.8%;

		.right-section {
			.item-image {
				min-width: calc((100% - (var(--distanceIssueItem) * 2)) / 1.5);
				max-width: calc(100% / 3);
				will-change: transform;
			}
		}

		.left-section {
			max-width: 100%;
		}
	}
`;

export default IssueSectionStyle;
