import gsap from 'gsap';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useParams, usePathname } from 'next/navigation';

//* HOC'S
import { withDataContext } from '@/context';

//* Style
import IssueSectionStyle from './style';

//* Helpers
import { config } from '@/helpers';

//* Components
import Text from '@/components/Text';
import Image from '@/components/Image';
import Banner from '@/components/Banner';
import Button from '@/components/Button';
import Container from '@/components/Container';
import Resize from '@/components/Resize';

const IssueSection = ({ className, globalData }) => {
	//! Data
	const { title, description, images } = { ...globalData.submit_issue };

	//! Next Navigation
	const pathname = usePathname();
	const params = useParams();

	//! State
	const [resize, setResize] = useState(false);

	//! Refs
	const titleRef = useRef();
	const descriptionRef = useRef();
	const containerRef = useRef();
	const triggerRef = useRef();

	//! Animate Section
	useEffect(() => {
		gsap.set('.anim-background', {
			scaleX: 1.3,
		});
	}, [pathname, params.lang]);

	useEffect(() => {
		triggerRef.current = ScrollTrigger.create({
			trigger: containerRef.current,
			start: 'top 80%',
			end: 'top top',
			once: true,
			// markers: true,

			onEnter: () => {
				gsap.to('.anim-background', {
					scaleX: 1,
				});
			},
		});

		return () => {
			if (triggerRef.current) {
				triggerRef.current.kill();
				triggerRef.current = null;
			}
		};
	}, [resize]);

	//! Item Issue gallery
	const imageIssue = useMemo(() => {
		if (images.length && images.length < 3) {
			images.push(...images);
		}
		return images.length
			? images.map((item, index) => {
					return (
						<div
							key={index}
							className='item-image'>
							<Image
								src={item.src}
								alt={item.alt}
							/>
						</div>
					);
			  })
			: null;
	}, []);

	return (
		<IssueSectionStyle
			ref={containerRef}
			className={className}>
			<Resize setResize={setResize}>
				<Container isSection>
					<div className='wrapper-issue'>
						<div className='anim-background' />

						<div className='left-section'>
							<Text
								ref={titleRef}
								tag={'h2'}
								className={'uppercase h2 white-color font-montserrat-arm-medium title-issue'}>
								{title}
							</Text>

							<Text
								ref={descriptionRef}
								className={'p2 white-color description-issue'}>
								{description}
							</Text>

							<Button
								btnType={'secondary'}
								text={`${config.routes.contact.name}`}
								className={'button-dowland reverse'}
								url={config.routes.contact.path}
							/>
						</div>

						<div className='right-section'>
							<Banner
								className={'image-banner'}
								component={imageIssue}
								trigger={true}
							/>
						</div>
					</div>
				</Container>
			</Resize>
		</IssueSectionStyle>
	);
};

export default withDataContext(IssueSection, ['globalData']);
