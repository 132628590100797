'use client';

//* Styles
import HomeTemplateStyle from './style';

//* Components
import Hero from './Hero';
import AboutHome from './About';
import WhatWeDo from './WhatWeDo';
import IssueSection from './IssueSection';
import NewsPosts from '../_sections/News';
import Reforms from '../_sections/Reforms';
import Partners from '../_sections/Partners';

const HomeTemplate = ({ pageData }) => {
	return (
		<HomeTemplateStyle id='home'>
			<Hero heroData={pageData.hero} />

			<AboutHome
				aboutData={pageData.about_us}
				statistics={pageData.statistics}
			/>
			<WhatWeDo {...pageData.what_we_do} />

			<Reforms />

			<IssueSection />

			<NewsPosts />

			<Partners />
		</HomeTemplateStyle>
	);
};

export default HomeTemplate;
