import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useEffect, useRef } from 'react';
import gsap from 'gsap/all';

//* Styles
import AboutHomeStyle from './style';

//* HOO'C
import { withUIContext } from '@/context';

//* Component
import Text from '@/components/Text';
import Container from '@/components/Container';

const AboutHome = ({ aboutData, statistics, winWidth }) => {
	//! Ref
	const wrapperStatisticsRef = useRef(null);
	const refTrigger = useRef(null);

	useEffect(() => {
		function numberWithCommas(x) {
			return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		}

		if (wrapperStatisticsRef.current) {
			const children = wrapperStatisticsRef.current.children;
			refTrigger.current = ScrollTrigger.create({
				start: `${winWidth >= 768 ? 'center' : 'top'} 80%`,
				end: 'top top',
				trigger: wrapperStatisticsRef.current,
				// markers: true,
				once: true,
				onEnter: () => {
					Array.from(children).forEach((item, i) => {
						gsap.to(item, {
							y: 0,
							ease: 'power2.out',
							duration: 0.8,
							delay: i * 0.1,
						});
						const itemsText = item.querySelector('.statistics-text-number');
						gsap.to(itemsText, { opacity: 1, duration: 0 });

						const fullText = itemsText.innerText;
						const match = fullText.match(/([+-])?(\d+)([kK]?)/);
						const sign = match[1] || '';
						const numberMatch = match[2];
						const suffix = match[3];
						if (numberMatch) {
							const startNumber = Number(numberMatch);

							gsap.from(itemsText, {
								duration: 1.2,
								textContent: 0,
								ease: 'power1.out',
								onUpdate: function () {
									const currentValue = Math.ceil(this.targets()[0].textContent);
									const animatedNumber = numberWithCommas(currentValue);
									itemsText.innerHTML = `${sign}${animatedNumber}${suffix}`;
								},
								onComplete: function () {
									itemsText.innerHTML = `${sign}${numberWithCommas(startNumber)}${suffix}`;
								},
							});
						}
					});
				},
			});
		}

		return () => {
			if (refTrigger.current) {
				refTrigger.current.kill();
				refTrigger.current = null;
			}
		};
	}, []);

	return (
		<AboutHomeStyle>
			<Container>
				<Container
					full
					row>
					<div className='empty col-3 col-t-1' />
					<div className='col-wrapper col-6 col-t-10 col-m-12'>
						<div className='wrapper-title-description'>
							<Text className={'uppercase h2 font-montserrat-arm-medium'}>{aboutData.title}</Text>
							<Text className={'p1 text-center'}>{aboutData.description}</Text>
						</div>
					</div>
					<div className='empty col-3 col-t-1' />
				</Container>
				<div
					ref={wrapperStatisticsRef}
					className='wrapper-statistics'>
					{statistics.length
						? statistics.map((item, i) => {
								return (
									<div
										key={i}
										className='item-wrapper-statistics margin-null'>
										<div className='wrapper-statistic-number'>
											<Text className={'statistics-text-number font-montserrat-arm-medium opacity-0'}>{item.additional_info.number}</Text>
										</div>
										<Text className={'statistics-text-title p1'}>{item.title}</Text>
									</div>
								);
						  })
						: null}
				</div>
			</Container>
		</AboutHomeStyle>
	);
};

export default withUIContext(AboutHome, ['winWidth']);
