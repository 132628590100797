import styled from 'styled-components';

const WhatWeDoStyle = styled.section`
	--distanceTitleDescription: var(--sp6x);
	--distanceItem: var(--sp5x);

	.wrapper-card {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: var(--distanceItem);
	}

	.pin-spacing-wrapper {
		margin-top: calc(var(--sectionDistance) / 2);
	}

	.wrapper-title-description-right-block {
		margin-top: calc(var(--sectionDistance) / 2);
		will-change: transform;

		.inner-wrapper {
			display: flex;
			flex-direction: column;
			gap: var(--distanceTitleDescription);
		}
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) {
		--distanceTitleDescription: var(--sp5x);
		--distanceItem: var(--sp4x);
	}
	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) {
		--distanceTitleDescription: var(--sp4x);
		--distanceItem: var(--sp3x);
	}
	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) {
		--distanceTitleDescription: var(--sp5x);
		--distanceItem: var(--sp2x);
	}
	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) {
		--distanceTitleDescription: var(--sp2x);
		--distanceItem: var(--sp1x);
	}
	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) {
		.empty {
			display: none;
		}
	}
	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		.pin-spacing-wrapper {
			margin-top: 0;
		}

		.wrapper-title-description-right-block {
			margin-bottom: var(--sp3x);

			.inner-wrapper {
				height: auto !important;
			}
		}
	}
`;
export default WhatWeDoStyle;
