import styled from 'styled-components';

const PartnersStyle = styled.section`
	--radiusBorder: var(--sp4x);
	--padTB: var(--sp3x);
	--padLR: var(--sp24x);
	--sizeImage: var(--sp21x);

	.wrapper-banner-partner {
		padding: var(--padTB) var(--padLR);
		background-color: var(--white);
		border-radius: var(--radiusBorder);

		.partners-banner {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: calc(100%);
		}

		.inner-wrapper {
			overflow: hidden;
		}

		.item-image {
			will-change: transform;

			.link-item {
				aspect-ratio: 1;
			}
		}

		.image-cont {
			--proportion: 0;
			overflow: hidden;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				position: relative !important;
				height: var(--sizeImage) !important;
				height: 100%;
				width: 100%;
				object-fit: contain;
			}
		}
	}
	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) {
		--radiusBorder: var(--sp3x);
		--padTB: var(--sp2x);
		--padLR: var(--sp10x);
		--sizeImage: var(--sp16x);
	}
	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) {
		--radiusBorder: var(--sp2-5x);
		--padTB: var(--sp1x);
		--padLR: var(--sp9x);
		--sizeImage: var(--sp13x);
	}
	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) {
		--radiusBorder: var(--sp2-5x);
		--padTB: var(--sp2x);
		--padLR: var(--sp8x);
	}
	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) {
		--radiusBorder: var(--sp2-5x);
		--padTB: var(--sp2x);
		--padLR: var(--sp7x);
	}
	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) {
		--radiusBorder: var(--sp2-5x);
		--padTB: var(--sp1x);
		--padLR: var(--sp3x);
		--sizeImage: var(--sp7x);
	}
	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--radiusBorder: var(--sp2-5x);
		--padTB: var(--sp1-5x);
		--padLR: var(--sp4x);
		--sizeImage: var(--sp10x);

		.wrapper-banner-partner {
			.item-image {
				display: flex;
				flex-direction: column;
				width: 100%;
				height: 100%;
			}
		}
	}
`;
export default PartnersStyle;
